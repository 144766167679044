var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_vm._t("nav-prepend")],2),_c('div',{staticClass:"tw-flex tw-flex-no-wrap tw-items-center tw-py-6 min-h-56 top-nav"},[_c('div',{ref:"topnav-left-action"},[_vm._t("left-action",[(_vm.isBack && _vm.showBackButton)?_c('BackButton',{staticClass:"top-nav__action tw-mr-4 pl-body",attrs:{"prev-route":_vm.prevRoute}}):_vm._e()])],2),_c('p',{ref:"topnav-title",class:[
        'tw-font-thin leading-24 tw-text-base tw-text-black tw-uppercase tw-truncate',
        _vm.titleClass,
        {
          'tw-mx-auto tw-text-center tw-font-normal tw-absolute tw-left-1/2': (_vm.centerTitle && _vm.isMobileView) || _vm.alwaysCenter
        },
        {
          'tw-flex tw-items-center': !_vm.logo
        } ],style:(("\n        transform: translateX(" + ((_vm.centerTitle && _vm.isMobileView) || _vm.alwaysCenter ? '-50%': '0') + ");\n        top: " + ((_vm.centerTitle && _vm.isMobileView) || _vm.alwaysCenter ? '24px': 'auto') + ";\n        "))},[(_vm.logo)?_c('img',{staticClass:"tw-h-5",attrs:{"src":"/img/app-logo.png","alt":"title"}}):[_vm._t("title-left"),_vm._v(" "+_vm._s(_vm.transformedTitle)+" "),_vm._t("title-right")]],2),_c('div',{ref:"topnav-right-action",staticClass:"tw-ml-auto tw-flex tw-items-center"},[_vm._t("right-actions"),(_vm.showNotificationIcon)?_c('NotificationIcon',{class:[
          'tw-h-6 tw-w-6',
          { 'tw-mr-6': _vm.showUserPhoto },
          { 'mr-body': !_vm.showUserPhoto } ]}):_vm._e(),(_vm.showUserPhoto)?_c('router-link',{staticClass:"mr-body tw-cursor-pointer tw-rounded-full",attrs:{"to":{ name: 'profile' },"tag":"div"}},[_c('UserPhoto',{attrs:{"photo-size":"tw-h-6 tw-w-6","self":""}})],1):_vm._e()],2)]),_c('div',[_vm._t("nav-append")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }