import Vue from 'vue';
import VueRouter from 'vue-router';
import _debounce from 'lodash/debounce';

import store from '../store';

import Auths from './Auths';
import User from './UserViews';

import { cleanUrl } from '../modules/dataHelper';


Vue.use(VueRouter);


const loadRoutes = async () => {
  const UserRoutes = await User();

  return [
    {
      path: '',
      redirect: '/login',
    },
    ...Auths,
    ...UserRoutes,
  ];
};

const loggedInUserData = store.dispatch('getLoggedInUserData');


const $router = async () => {
  const $routes = await loadRoutes();

  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: $routes,
  });

  // -------------------------------------------------------------------

  // ? notification redirects
  // stores the routes to after-login-redirector
  // [Good for stats]
  router.beforeEach(async (to, from, next) => {
    const fullUrl = `${window.origin}/${to.path}`;
    const cleanedUrl = cleanUrl(fullUrl);
    // remove double tripple slash url
    if (/([^:])(\/{2,})/g.test(fullUrl)) {
      window.history.pushState(window.history.state, 'eMentored - admin dashboard', cleanedUrl);
    }

    if (/(\/app\/n)(\/\S)/.test(cleanedUrl)) {
      const redirectTo = to.path.split(/(\/app\/n){1}/)[2];
      sessionStorage.setItem('after-login-redirector', `/app${redirectTo}`);
      next(`/app${redirectTo}`);
    } else {
      next();
    }
  });

  // -------------------------------------------------------------------

  // ? auth
  router.beforeEach(async (to, from, next) => {
    await loggedInUserData;

    const loginAuthError = (
      (to.matched.some((record) => record.meta.requiresLogin) && !store.getters.isLoggedIn)
        || (to.matched.some((record) => record.meta.requiresUserType) && !store.state.User.type)
    );
    //
    const superAdminPermissionError = (
      to.matched.some((record) => record.meta.requiresUserType === store.state.User.accountTypes.superAdmin)
        && store.state.User.type !== store.state.User.accountTypes.superAdmin
    );
    //
    const staffAdminPermissionError = (
      to.matched.some((record) => record.meta.requiresUserType === store.state.User.accountTypes.staff)
        && store.state.User.type !== store.state.User.accountTypes.staff
    );
    //

    const loginRedirect = _debounce((commitMessage = 'You need to log in.') => {
      store.commit('authError', commitMessage);
      next('/login');
    }, 300, { leading: true });

    //
    if (loginAuthError) {
      console.warn('LOGIN AUTH ERROR!!!');
      return loginRedirect();
    }

    if (superAdminPermissionError) {
      console.error('SUPERADMIN AUTH ERROR!!!', to);
      return loginRedirect('You need to be a superadmin to view this page.');
    }

    // only throw staff auth error if user is not super adminn
    if (staffAdminPermissionError && superAdminPermissionError) {
      console.error('STAFF AUTH ERROR!!!', to);
      return loginRedirect('You need to be a staff admin to view this page.');
    }

    //
    next();
    return true;
  });

  // -------------------------------------------------------------------

  // ? save previous route
  // checks if the route name is set
  router.afterEach((to, from) => {
    // if route is unkown, redirect to app
    if (!to.name) {
      console.warn('unkown route. Redirecting to app...');
      setTimeout(() => {
        window.location.assign(`${window.location.origin}/app`);
      }, 300);
    }

    // sessionStorage navigation history
    if (from.name) {
      sessionStorage.setItem('previousroutename', from.name);
      sessionStorage.setItem('previousroutequery', JSON.stringify(from.query || {}));
      sessionStorage.setItem('previousrouteparams', JSON.stringify(from.params || {}));
    }
  });

  // -------------------------------------------------------------------

  return router;
};

export default $router;
