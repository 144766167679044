export default {
  isLoggedIn(state) {
    return !!state.id;
  },
  userId(state) {
    return Number(state.id);
  },
  userTypeName: (state) => (type = state.type) => {
    const index = Object.values(state.accountTypes).indexOf(Number(type));
    return Object.keys(state.accountTypes)[index];
  },
  userLicenseType(state) {
    return Number(state.licensetype);
  },
  userCompanyId(state) {
    return Number(state.companyid);
  },
  adminUserTypes(state) {
    return {
      superAdmin: state.accountTypes.superAdmin,
      staff: state.accountTypes.staff,
    };
  },
};
