import store from '../../store';
import ResourcesView from '../../views/Resources/ResourcesView.vue';

const meta = {
  requiresLogin: true,
  requiresUserType: store.state.User.accountTypes.staff,
};

export default [
  {
    path: 'resources',
    name: 'resources',
    component: ResourcesView,
    meta,
  },
];
