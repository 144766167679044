<template>
  <form
    id="share-resource"
    ref="share-resource-form"
    class="tw-max-w-full md:tw-w-2/3 tw-mb-12 auth-card tw-overflow-hidden-- tw-relative"
    method="post"
    action="/share-resource"
    @submit.prevent="onSubmit"
  >

  <transition-group
    name="slide-right"
    tag="div"
    class="tw-w-full"
    mode="out-in"
    appear
  >
    <template v-if="step !== 2">
      <!-- SHARE TO -->
      <div key="step-1-body">
        <div class="tw-pt-6 tw-border-b tw-pb-4 tw-mb-8">
          <p class="tw-text-center opacity-54 tw-text-xs">
            Users
          </p>
        </div>

        <div class="tw-my-4">
          <div class="tw-m-4">
            <v-select
              placeholder="Specific person"
              :class="[
                { '--loading-data': loadingForm },
                'my-select tw-bg-app-deep-blue-11 tw-border-b tw-border-black tw-text-black tw-rounded-t-3 tw-rounded-b-none tw-text-sm tw-flex-shrink-0',
              ]"
              v-model="form.sendToUserId"
              @input="selectUserGroup(['specific', true])"
              :value="(users[0] || {}).id"
              :options="users"
              :reduce="option => option.id"
              searchable
              filterable
              clearable
            />

            <!-- <div class="tw-w-full tw-border-b tw-py-4 tw-text-right" key="step-1-footer">
              <BaseButton
                class="tw-py-3 tw-bg-black tw-text-white tw-border-0 tw-shadow-xl tw-uppercase"
                text="next"
                :disabled="!!form.sendToUserId === false"
                @click="step = 2"
              />
            </div> -->
            <div class="tw-py-4">
              <p class="tw-text-center opacity-54 tw-text-xs">
                or
              </p>
            </div>
          </div>
          <div class="tw-m-4">
            <!-- <v-select
              placeholder="Company"
              :class="[
                { '--loading-data': loadingForm },
                'my-select tw-bg-app-deep-blue-11 tw-border-b tw-border-black tw-text-black tw-rounded-t-3 tw-rounded-b-none tw-text-sm tw-flex-shrink-0'
              ]"
              v-model="form.sendToCompanyId"
              :value="(companies[1] || {}).id"
              :options="companies"
              :reduce="option => option.id"
              searchable
              filterable
              clearable
              v-if="isSuperAdmin"
            /> -->
            <!-- <h3 class="tw-text-xl font-title">eMentored users</h3> -->
          </div>

          <div
            :class="{ '--loading-data': loadingForm }"
          >
            <div class="tw-m-4">
              <BaseCheckBox
                @change="selectUserGroup(arguments)"
                :checked="form.sendTo === 'all'"
                id="every"
                :label="`Everyone ${selectedCompany.label ? `on ${selectedCompany.label}` : ''}`"
                value="all"
                class="tw-text-sm"
              />
            </div>
            <div class="tw-m-4">
              <BaseCheckBox
                @change="selectUserGroup(arguments, accountTypes.mentor)"
                :checked="form.sendTo === 'mentors'"
                id="mentors"
                :label="`Mentors ${selectedCompany.label ? `on ${selectedCompany.label}` : ''}`"
                value="mentors"
                class="tw-text-sm"
              />
            </div>
            <div class="tw-m-4">
              <BaseCheckBox
                @change="selectUserGroup(arguments, accountTypes.mentee)"
                :checked="form.sendTo === 'mentees'"
                id="mentees"
                :label="`Mentees ${selectedCompany.label ? `on ${selectedCompany.label}` : ''}`"
                value="mentees"
                class="tw-text-sm"
              />
            </div>
          </div>
        </div>

        <div class="tw-border-t tw-mx-4 tw-py-4 tw-text-right" key="step-1-footer">
          <BaseButton
            class="tw-py-3 tw-bg-black tw-text-white tw-border-0 tw-shadow-xl tw-uppercase"
            text="next"
            :disabled="!canGoToNext"
            @click="step = 2"
          />
        </div>
      </div>

    </template>

    <template v-else>
      <div class="tw-flex tw-flex-no-wrap tw-justify-between tw-border-b tw-mb-4 tw-pb-4 tw-pt-6 tw-items-center" key="step-2-header">
        <div class="tw-w-1/2">
          <BaseButton
            text="back"
            @click="step = 1"
          />
        </div>
        <p class="tw-text-right tw-text-app-yellow opacity-87 tw-text-sm">
          {{ sendToName }}
        </p>
      </div>

      <div class="tw-w-full tw-py-4 tw-mb-4" key="step-2-body">
        <div class="tw-m-1 tw-mb-4">
          <p class="tw-text-center opacity-54 tw-text-xs">
            Upload media
          </p>
        </div>

        <div class="tw-mb-1">
          <InputGroup
            label="Resource file"
            name="resource-file"
            ref="resource-file-input"
            instruct="File must be less than 8MBS"
            type="file"
            accepts="*"
            placeholder="Upload a file"
            @input="fileChange"
            :error="form.errors.file"
          />
        </div>

        <div class="tw-mb-1">
          <InputGroup
            label="Resource Name"
            name="resource-name"
            ref="resource-name"
            type="text"
            placeholder="Resource name"
            v-model.lazy="form.upload.name"
            :value="form.upload.name"
            :error="form.errors.name"
          />
        </div>
      </div>

      <div class="tw-w-full tw-border-t tw-p-4 tw-py-8" key="step-2-footer">
        <BaseButton
          class="tw-py-3 tw-bg-black tw-text-white tw-block tw-border-0 tw-w-full tw-shadow-xl tw-uppercase"
          :text="uploadButtonText"
          :disabled="!canUploadResource || !uploadResource"
          type="submit"
        />
      </div>
    </template>
  </transition-group>

  </form>
</template>

<script>
import { mapState } from 'vuex';
import form from '@/modules/formHelper';
import BaseCheckBox from '@/components/BaseCheckBox.vue';
import InputGroup from '@/components/InputGroup.vue';

import { maxFileSize } from '@/modules/misc';
import { toBoolean } from '@/modules/dataHelper';

export default {
  name: 'ShareResources',
  components: {
    BaseCheckBox,
    InputGroup,
  },
  props: {
    prompUpload: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadingForm: true,
      step: 1, // this is a two step form
      uploadResource: true,
      uploadButtonText: 'upload',
      uploadModalOpen: (this.prompUpload || toBoolean(this.$route.query['prompt-upload'])), // * should be false if query is not found
      companies: [],
      users: [],
      form: {
        sendTo: undefined, // all, specific, mentors, mentees
        sendToUserId: undefined,
        sendToCompanyId: undefined,
        upload: {
          file: undefined,
          name: '',
          progress: 0,
        },
        errors: {
          sendTo: undefined,
          file: undefined,
          name: undefined,
        },
      },
    };
  },
  watch: {
    // eslint-disable-next-line func-names
    'form.upload.file': function (val) {
      if (!val) {
        this.form.upload.file = undefined;
        this.form.upload.name = undefined;
        this.form.errors.file = 'File required';
        this.form.errors.name = 'File name required';
        return false;
      }
      this.form.errors.file = undefined;
      this.form.errors.name = undefined;

      if (val.size > maxFileSize) {
        this.form.upload.file = undefined;
        this.form.upload.name = undefined;

        this.$toasted.info('Please upload a file 8MB maximum', {
          duration: 4000,
          position: 'top-right',
        });

        if (this.$refs['resource-file-input']) {
          console.log(this.$refs['resource-file-input']);
          const uploadFileInput = this.$refs['resource-file-input'].$el.querySelector('input');
          uploadFileInput.value = null;
        }
        this.clearFileNameInput();

        return false;
      }

      if (!form.isValidFile(val) && val) {
        this.form.errors.file = 'Invalid file type';
        this.form.upload.file = undefined;
        this.form.upload.name = undefined;
        this.clearFileNameInput();
        return false;
      }
      this.form.upload.name = val.name.split('.').slice(0, -1).join('');

      this.clearFileNameInput(this.form.upload.name || '');
      return true;
    },
    // eslint-disable-next-line func-names
    'form.upload.name': function (val) {
      if (!form.isValidTextInput(val)) {
        this.form.errors.name = 'File name required';
        return;
      }

      this.form.errors.name = undefined;
    },
  },
  computed: {
    ...mapState({
      accountTypes: (state) => state.User.accountTypes,
    }),
    sendToName() {
      if (this.form.sendToUserId) {
        // selected userId
        const user = this.users.find((u) => Number(u.id) === Number(this.form.sendToUserId));
        // console.log(user);
        if (user) {
          return user.label;
        }
      }

      return `${this.form.sendTo} ${this.selectedCompany.label ? `on ${this.selectedCompany.label}` : ''}`;
    },
    selectedCompany() {
      if (!this.form.sendToCompanyId) return {};

      return this.companies.find((c) => Number(c.id) === Number(this.form.sendToCompanyId));
    },
    canUploadResource() {
      return form.isValidFile(this.form.upload.file)
        && form.isValidTextInput(this.form.upload.name)
        && this.form.upload.file.size < maxFileSize
        && !!this.form.sendTo
        && this.loadingForm === false;
    },
    canGoToNext() {
      return !!this.form.sendTo
        && this.loadingForm === false
        && (this.form.sendTo === 'specific' ? !!this.form.sendToUserId : true);
    },
  },
  methods: {
    /**
    * set sendToUserId
    */
    selectUserGroup([group, checked]) {
      this.form.sendTo = checked ? group : '';
    },
    clearFileInput() {
      if (this.$refs['resource-file-input']) {
        const uploadFileInput = this.$refs['resource-file-input'].$el.querySelector('input');
        uploadFileInput.value = null;
      }
    },
    clearFileNameInput(val = '') {
      if (this.$refs['resource-name']) {
        const uploadNameInput = this.$refs['resource-name'].$el.querySelector('input');
        uploadNameInput.value = val;
        uploadNameInput.dispatchEvent(new Event('input'));
      }
    },
    fileChange(val) {
      [this.form.upload.file] = val;
    },
    async onSubmit() {
      await this.$nextTick();

      if (!this.canUploadResource || !this.uploadResource) return;

      this.uploadButtonText = 'uploading...';
      this.uploadResource = false;

      const [res, progress] = await this.$store.dispatch('shareResource', [
        this.form.upload.file,
        this.form.sendTo,
        this.form.sendToCompanyId,
        this.form.sendToUserId,
      ]);


      // wait for upload to complete to show success message
      // eslint-disable-next-line no-unused-vars, no-async-promise-executor
      const r = await new Promise(async (resolve, reject) => {
        const $this = this;

        res.then((_r) => {
          resolve(_r);
        });

        setTimeout(async function again() {
          const p = progress[0];
          // console.log(progress[0]);
          $this.uploadButtonText = `working... (${p}%)`;
          if (p < 100) {
            setTimeout(again, 100);
          } else {
            await resolve((await res));
          }
        }, 500);
      }).then(async (result) => {
        await this.$nextTick();

        this.uploadButtonText = 'upload';
        this.uploadResource = true;

        if (!result) {
          this.$toasted.global.appError();
          return;
        }

        this.$toasted.success(`Resource ${this.form.upload.name} shared`, {
          duration: 2000,
          position: 'top-right',
        });

        await this.$nextTick();

        this.$emit('resource-shared');

        await this.$nextTick();

        this.clearFileInput();
        this.clearFileNameInput();
      });
    },
    async getUsers(companyIds = []) {
      const requests = [];

      companyIds.forEach((companyId) => {
        const mentorAccounts = this.$store.dispatch(
          'getAccounts',
          ['mentor', 'verified', companyId],
        );
        const menteeAccounts = this.$store.dispatch(
          'getAccounts',
          ['mentee', 'verified', companyId],
        );

        requests.push(mentorAccounts, menteeAccounts);
      });

      const results = await Promise.all(requests).then((res) => {
        // eslint-disable-next-line no-underscore-dangle
        const _res = [];
        res.forEach((__res) => {
          _res.push(...__res);
        });
        return _res;
      });

      return results;
    },
  },
  async created() {
    this.loadingForm = true;

    const myCompany = await this.$store.dispatch('myCompany');
    this.companies = [{
      id: myCompany.id,
      label: myCompany.company,
    }];
    this.form.sendToCompanyId = myCompany.id;

    const users = await this.getUsers([myCompany.id]);

    this.users = users.map((c) => ({
      id: c.id,
      label: `${c.name} ${c.middle || c.last_name} (${c.email})`,
    }));

    this.loadingForm = false;
  },
};
</script>

<style>

</style>
