// eslint-disable-next-line import/no-webpack-loader-syntax
import ReportWriter from 'worker-loader!../../modules/workers/reportWriter';
import { download } from '../../modules/dataHelper';

export default {
  writeReport(context, { report, reportType, reportName }) {
    // ? how to preserve statuses even on page reloading
    context.commit(
      'saveReportStatus',
      [reportType,
        {
          running: true,
          complete: false,
          reportName: reportName || reportType,
        },
      ],
    );

    // ? how to preserve worker even on page reloading
    const Worker = new ReportWriter();
    Worker.onmessage = (data) => {
      context.dispatch('workerMessage', data);
    };

    Worker.onerror = (data) => {
      context.dispatch('workerError', data);
    };

    // ! start writing report
    Worker.postMessage({
      id: reportType,
      message: 'write',
      data: report,
    });
  },

  /**
   * Report Worker onmessage event handler
   * @param {Object} message {id {String}, data {Boolean|Object}, message {String} }
   */
  workerMessage(context, { currentTarget: worker, data: { id, message, data } }) {
    console.log(id);
    switch (message) {
      case 'error':
        return context.dispatch('reportError', {
          worker, id, message, data,
        });
      case 'report-canceled':
      case 'report-ready':
        return context.dispatch('reportFinished', {
          worker, id, message, data,
        });
      default:
        break;
    }

    // ! handle unkown worker messages
    console.log(worker, id, message, data);

    return true;
  },

  /**
   * Report Worker onerror event handler
   * ? Do what
   */
  workerError(context, err) {
    console.error('======\n\n\nWeb Worker has an error:\n', err, '\n\n\n======');
  },

  // ? update status?
  reportError(context, {
    worker, id, message, data,
  }) {
    console.error('Error in writing the report', message.errorMessage);
    console.error('\n', data);

    context.commit('saveReportStatus', [id, { running: false, complete: false }]);

    worker.terminate();

    setTimeout(() => {
      context.commit('removeReportStatus', id);
    }, 500);
  },

  // ? prompt download ?
  reportFinished(context, {
    worker, id, message, data,
  }) {
    console.log('finished!', message);
    const reportName = (context.getters.getReportStatus(id) || {}).reportName || id;

    context.commit('saveReportStatus', [id, { running: false, complete: true, reportName }]);

    // const object = document.getElementById('test-pdf') || document.createElement('iframe');

    // if (!document.getElementById('test-pdf')) {
    //   object.id = 'test-pdf';
    //   object.width = '400';
    //   object.height = '600';

    //   object.style.position = 'absolute';
    //   object.style.zIndex = '3000';
    // }

    // object.src = window.URL.createObjectURL(data);
    // document.body.prepend(object);

    setTimeout(() => {
      // TODO: download on user @click toast or somethind
      download(data, `${reportName}.pdf`);

      worker.terminate();
      context.commit('removeReportStatus', id);
    }, 500);
  },
};
