import getters from './getters';
import mutations from './mutations';
import actions from './actions/index';
import modules from './modules';

export default {
  namespaced: false,
  state: {
    authError: undefined,
    // id: 12,
    // url: 'url',
    // name: 'Tina',
    // type: 2,
    // licensetype: 1,
    // companyid: 1,
    id: undefined, // apiStore.getItem('userid')
    url: undefined, // apiStore.getItem('userurl')
    type: undefined, // Number(await apiStore.getItem('usertype'))
    name: undefined, // apiStore.getItem('username')
    licensetype: undefined, // apiStore.getItem('licensetype')
    companyid: undefined, // apiStore.getItem('companyid')

    // [TODO] GET THIS FROM SERVER
    accountTypes: {
      staff: 1, // level
      superAdmin: 2, // level
      mentor: 3, // type
      mentee: 4, // type
      all: 5, // type
      // corporate: 5,
    },
  },
  getters,
  mutations,
  actions,
  modules,
};
