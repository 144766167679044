<template>
  <div class="tw-flex tw-items-start">
    <div class="tw-w-3/5-- tw-mr-3 tw-relative">
      <PhotoInput
        :photo="bgImageUrl"
        photo-size-class="falsy-to-make-style-work"
        bg-image-class="tw-object-cover"
        style="width: 228px; height: 142px;"
        @previewing="bgImageUrl = $event"
        @change="form.bgImage = $event"
      >
        <template slot="overlay">
          <span
            style="transform: translateX(-50%) translateY(-50%); top: 50%;"
            class="tw-z-10 tw-text-xs leading-17 tw-absolute tw-left-1/2 tw-text-center tw-w-full font-title tw-text-app-white-87 tw-capitalize"
          >
            {{form.text}}
          </span>
        </template>
      </PhotoInput>
      <!-- canvas -->
    </div>
    <div>
      <!-- inputs -->
      <InputGroup
        label="Text"
        name="text"
        type="text"
        placeholder="landing page text"
        input-classes="tw-text-sm"
        v-model="form.text"
        class="tw-mb-4"
      />
      <div>
        <p class="tw-block tw-text-xs tw-mb-6 opacity-78 tw-text-left tw-italic" style="max-width: 186px;">
          Click on the box to upload a background image for the landing page.
        </p>
      </div>
      <!-- <InputGroup
        label="Background image"
        name="bg"
        type="file"
        placeholder="landing page background image"
        input-classes="tw-text-sm"
        v-model="form.bgImage"
      /> -->
      <div class="tw-text-right">
        <BaseButton
          :text="saveBtnText"
          class="tw-text-white tw-bg-black"
          :disabled="!canSave || saveBtnDisabled"
          @click="save"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InputGroup from '@/components/InputGroup.vue';
import PhotoInput from '@/components/PhotoInput.vue';

export default {
  name: 'CompanyCustomLandingPage',
  components: {
    InputGroup,
    PhotoInput,
  },
  props: {
    companyId: {
      type: [String, Number],
      required: true,
    },
    companyLandingpageText: {
      type: String,
      default: '',
    },
    companyLandingpageBgImage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      saveBtnDisabled: false,
      saveBtnText: 'save',
      bgImageUrl: this.companyLandingpageBgImage,
      form: {
        text: this.companyLandingpageText,
        bgImage: undefined,
      },
    };
  },
  computed: {
    canSave() {
      return (!!this.form.text.length) || typeof this.form.bgImage === 'object';
    },
  },
  methods: {
    async save() {
      if (!this.canSave) return;

      this.saveBtnDisabled = true;
      this.saveBtnText = 'working...';

      const result = await this.$store.dispatch('addCompanyUISettings', [this.companyId, this.form]);

      if (!result) {
        this.$toasted.global.appError({ errorMessage: 'settings could not be saved, please try again.' });
      } else {
        this.$toasted.success('Settings saved', {
          duration: 3000,
          position: 'top-right',
        });

        this.$emit('save', this.form);
      }

      await this.$nextTick();

      this.saveBtnDisabled = false;
      this.saveBtnText = 'save';

      await this.$nextTick();
    },
  },
};
</script>

<style>

</style>
