import store from '../../store';

const meta = {
  requiresLogin: true,
  requiresUserType: store.state.User.accountTypes.staff,
};

export default [
  {
    path: 'accounts',
    name: 'accounts',
    component() {
      return import(/* webpackChunkName: "staffviews" */ '../../views/Accounts/AccountsView.vue');
    },
    meta,
  },
  {
    path: 'accounts/create/:slug',
    name: 'createAccount',
    props: true,
    component() {
      return import(/* webpackChunkName: "staffviews" */ '../../views/Accounts/AccountsView.vue');
    },
    meta,
  },
];
