import goals from './goals';
import messages from './messages';
// import links from './links';
import logo from './logo';
import theme from './theme';

export default {
  ...goals,
  ...messages,
  // ...links,
  ...logo,
  ...theme,
};
