<template>
  <div class="app-bg tw-font-sans tw-relative">
    <template v-if="!loading">

      <MenuNav @logout-click="logout()" />

      <!-- <RightSideNav /> -->

      <transition :name="isMobileView ? 'new' : 'fade'" mode="out-in">
        <PopOver v-if="popOverIsOpen" />
      </transition>

      <transition name="fade" mode="out-in" appear>
        <BaseModal />
      </transition>

    </template>

    <div v-if="appError || xhrError">
      <div class="app-view">
        <p class="tw-text-center tw-py-10 tw-text-red-500" v-html="appError || xhrError"></p>
      </div>
    </div>

    <div v-else-if="loading">
      <div class="app-view">
        <p class="tw-text-center tw-py-10 opacity-31 tw-py-48">Loading...</p>
      </div>
    </div>

    <transition v-else :name="transitionName" :mode="transitionMode">
      <router-view />
    </transition>

  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import userAppMixin from '@/mixins/userApp';

import MenuNav from '@/views/_partials/MenuNav.vue';
import BaseModal from '@/components/BaseModal.vue';
import PopOver from '@/components/PopOver.vue';

const appErrorMessage = 'Sorry, an error occured. Check your internet connection.';

export default {
  name: 'Dashboard',
  components: {
    MenuNav,
    BaseModal,
    PopOver,
  },
  mixins: [userAppMixin],
  data() {
    return {
      loading: true,
      appError: undefined,
      transitionName: 'fade',
      transitionMode: 'out-in',
      // registering stores will be decided by admin type
      storeModulesNames: {
        Matches: undefined,
        Licenses: undefined,
        Stats: undefined,
        Resources: undefined,
        Accounts: undefined,
        Classes: undefined,
        Packages: undefined,
      },
    };
  },
  computed: {
    ...mapState({
      xhrError: (state) => state.Ui.errors.xhrError,
    }),
    ...mapGetters([
      'popOverIsOpen',
    ]),
  },
  methods: {
    // loads the ui settings
    async uiSettings() {
      await this.$store.dispatch('companyUiSettings', []);
      //
    },
    /**
     * strict checking admin details
     *    session expiry
     */
    async checkAdminInfo() {
      if (Number(process.env.VUE_APP_STATIC_USER)) return true;
      await this.$store.dispatch('getLoggedInUserData');
      await this.$store.dispatch('myCompany');

      const userDataMatch = await this.$store.dispatch('logUser', true);
      // console.log(userDataMatch);

      if (!userDataMatch) {
        await this.logout();
        return false;
      }

      console.log(`hello ${this.$store.state.User.name}`, '\n\n---------------');

      await this.$nextTick();
      await this.$nextTick();

      return true;
    },
  },
  async created() {
    // redirect from /app to /app/stats/accounts
    await this.$nextTick();
    this.$store.commit('xhrError');

    return this.checkAdminInfo()
      .catch((err) => {
        console.warn('adminApp err: ', err);
        this.$toasted.global.appError();
        this.appError = appErrorMessage;
        return false;
      })
      .then(async (res) => {
        // console.info('user info status', res);
        if (!res) {
          await this.logout('You need to log in.');
          return false;
        }

        const fns = [];

        // * load company ui settings
        fns[fns.length] = this.uiSettings();

        // * register modules
        fns[fns.length] = (async () => {
          if (this.isSuperAdmin) {
            // * register superadmin stores
            this.storeModulesNames.Licenses = import('../store/Licenses/index');
            this.storeModulesNames.Classes = import('../store/Classes/index');
            this.storeModulesNames.Packages = import('../store/Packages/index');
          }

          this.storeModulesNames.Matches = import('../store/Matches/index');
          this.storeModulesNames.Accounts = import('../store/Accounts/index');
          this.storeModulesNames.Resources = import('../store/Resources/index');
          this.storeModulesNames.Stats = import('../store/Stats/index');


          const modules = Object.entries(this.storeModulesNames);
          const modulesPromises = [];

          modules.forEach(([moduleName, module]) => {
            if (module) {
              const r = (async () => {
                const m = await module;
                await this.registerStoreModule(moduleName, m.default);
              })();

              modulesPromises.push(r);
            }
          });

          await Promise.all(modulesPromises);

          await this.$nextTick();
        })();

        await Promise.all(fns);

        this.loading = false;
        this.appError = undefined;

        if (this.$route.name === 'app') {
          this.$router.push({ name: 'accStats' });
        }

        return true;
      });
  },
};
</script>
