<template>
  <div>
    <PhotoInput
      @change="savePhoto"
      :photo="logo"
    />
  </div>
</template>

<script>
import PhotoInput from '@/components/PhotoInput.vue';

export default {
  name: 'CompanyCustomLogo',
  components: {
    PhotoInput,
  },
  props: {
    companyId: {
      type: [String, Number],
      required: true,
    },
    companyLogo: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      logo: this.companyLogo,
    };
  },
  methods: {
    async savePhoto(photoFile) {
      if (!photoFile) return;

      const result = await this.$store.dispatch('addCompanyLogo', [this.companyId, photoFile]);

      if (!result) {
        this.$toasted.global.appError({ errorMessage: 'Company logo could not be uploaded, please try again.' });
      } else {
        this.$toasted.success('Company logo saved!', {
          duration: 3000,
          position: 'top-right',
        });

        this.$emit('save');
      }
    },
  },
  created() {
    // get company then
  },
};
</script>

<style>

</style>
