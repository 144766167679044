<template>
  <form
    id="goal-form"
    ref="goal-form"
    class="tw-max-w-full tw-flex-shrink-0 tw-mx-auto tw-mb-20 auth-card tw-flex tw-flex-wrap"
    method="post"
    action="/goal/create"
    @submit.prevent="onSubmit"
  >

    <div class="tw-w-full tw-mb-4">
      <h1 class="tw-text-4xl font-title">{{ goalId ? 'Edit Goal' : 'Create a Goal' }}</h1>
      <!-- <h6 class="tw-text-base opacity-78 tw-text-app-deep-blue">Please fill all inputs</h6> -->
    </div>

    <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1">
      <!-- SELECT GROUP IF GROUPID IS NOT SET -->
      <SelectGroup
        :class="{ '--loading-data': loadingForm }"
        label="Pillar Group"
        name="groupid"
        nameKey="goal_group"
        placeholder="group"
        input-classes="my-select--huge tw-bg-gray-200 tw-text-left"
        v-model="form.groupId"
        :selected="groupId"
        :options="pillarGroups"
        :reduce="pillar => pillar.id"
        :clearable="false"
        :error="errors.groupId"
      />
    </div>
    <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1">
      <InputGroup
        :class="{ '--loading-data': loadingForm }"
        label="Name"
        name="name"
        type="text"
        placeholder="name"
        input-classes="tw-p-3"
        input-type=""
        v-model="form.name"
        :error="errors.name"
      />
    </div>
    <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1">
      <TextareaGroup
        :class="{ '--loading-data': loadingForm }"
        label="Description"
        instruct="Give a small description about the goal"
        name="description"
        type="text"
        placeholder="description"
        input-classes="tw-p-3 focus:tw-bg-gray-100"
        v-model="form.description"
        :rows="4"
        :error="errors.description"
      />
    </div>

    <div class="tw-w-full tw-flex-shrink-0 tw-px-1 tw-mb-4 tw-pt-10 tw-flex tw-justify-between">
      <div>
        <BaseButton
          type="submit"
          :class="[
            'tw-block tw-w-full md:tw-inline-block md:tw-w-auto tw-mb-4 tw-py-3 tw-px-8',
            `${btnClass}`
          ]"
          :text="btnText"
          :disabled="!canSubmit || btnDisabled"
        />
      </div>

      <!-- <div v-if="goalId">
        <BaseButton
          class="tw-bg-red-100 opacity-54 hover:tw-opacity-100 tw-border-red-600 tw-text-red-600 tw-inline-block tw-mb-4 tw-py-3 transition-fast"
          text="Delete"
          confirm
          @click="deleteGoal()"
        />
      </div> -->
    </div>

  </form>
</template>

<script>
import _debounce from 'lodash/debounce';

import TextareaGroup from '@/components/TextareaGroup.vue';
import InputGroup from '@/components/InputGroup.vue';
import SelectGroup from '@/components/SelectGroup.vue';

import form from '@/modules/formHelper';

export default {
  name: 'EditGoal',
  components: {
    TextareaGroup,
    InputGroup,
    SelectGroup,
  },
  props: {
    company: {
      type: Object,
      required: true,
    },
    groupId: {
      type: [String, Number],
      default: undefined,
    },
    goalId: {
      type: [String, Number],
      default: undefined,
    },
    btnClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loadingForm: true,
      errorForm: undefined,
      btnText: 'Save',
      btnDisabled: false,
      pillarGroups: [],
      errors: {},
      form: {
        groupId: undefined, // for
        name: undefined,
        description: undefined,
      },
    };
  },
  watch: {
    // eslint-disable-next-line func-names
    'company.id': function () {
      this.resetForm();
      this.loadForm();
    },
    groupId() {
      this.resetForm();
      this.loadForm();
    },
    goalId() {
      this.resetForm();
      this.loadForm();
    },
  },
  computed: {
    canSubmit() {
      return form.isValidTextInput(this.form.name)
        && !this.loadingForm
        && form.isValidTextInput(this.form.description)
        && this.form.groupId !== undefined;
    },
  },
  methods: {
    resetForm() {
      this.form = {
        groupId: undefined, // for
        name: undefined,
        description: undefined,
      };
    },
    // eslint-disable-next-line func-names
    loadForm: _debounce(async function () {
      this.loadingForm = true;
      this.errorForm = undefined;

      // * get list of company pillar groups
      this.pillarGroups = (await this.$store.dispatch('getPillarGroups', [this.company.id, false]) || []);

      // * get goal details if id is defined
      if (this.goalId !== undefined) {
        const goals = (await this.$store.dispatch('getGoals', [this.company.id, false]) || []);
        const curr = goals.find((g) => Number(g.id) === Number(this.goalId)) || {};

        this.form.name = curr.goal || '';
        this.form.description = curr.description || '';
      }

      await this.$nextTick();
      await this.$nextTick();

      this.form.groupId = this.groupId;
      await this.$nextTick();

      this.loadingForm = false;
    }, 300),
    async onSubmit() {
      if (!this.canSubmit) {
        this.$toasted.global.appError({
          errorMessage: 'You can\'t submit the form. Check for empty inputs',
        });

        console.warn('Cannot submit form... \n check for empty inputs');
        return false;
      }

      // signup!!
      this.btnDisabled = true;
      this.btnText = 'working...';

      const data = {
        ...(this.form),
        companyId: this.company.id,
        goalId: this.goalId,
      };

      const result = await this.$store.dispatch('saveGoal', data);

      if (result) {
        this.$toasted.success('Saved!', { duration: 1000 });
        this.$emit('created-success');
      } else {
        this.$toasted.global.appError({
          errorMessage: 'Saving goal failed. Please try again later',
        });
      }

      this.btnDisabled = false;
      this.btnText = 'save';

      return result;
    },
  },
  async created() {
    this.errors = { ...this.form };

    await this.$nextTick();
    await this.loadForm();
    await this.$nextTick();

    this.loadingForm = false;
  },
};
</script>

<style>

</style>
