<template>
  <div class="tw-mb-2 tw-block">
    <slot name="input-label">
      <label class="tw-block tw-text-sm tw-mb-2 opacity-78 tw-text-left" :for="name" :aria-label="name" v-html="label">
      </label>
    </slot>
    <label v-if="instruct.length > 0" class="tw-block tw-text-left tw-text-xs tw-mb-2 tw--mt-3 opacity-54">
      {{ instruct }}
    </label>

    <v-select
      :class="['my-select tw-w-full transition', inputClasses]"
      placeholder=""
      v-model.lazy="selectValue"
      :value="selected"
      :label="nameKey"
      :options="options"
      :reduce="reduce"
      :multiple="multiple"
      :clearable="clearable"
      :filterable="filterable"
      :searchable="searchable"
    >
      <slot name="select-option" v-slot:option/>
    </v-select>
    <slot name="error-message">
      <p class="tw-text-red-500 tw-text-left tw-text-xs tw-italic" v-if="showError"> {{ error }} </p>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'SelectGroup',
  props: {
    error: {
      type: String,
      default: '',
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
    instruct: {
      type: String,
      default: '',
      required: false,
    },
    nameKey: {
      type: String,
      required: true,
      default: 'name',
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    reduce: {
      type: Function,
      default() {
        return () => {};
      },
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    filterable: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    selected: undefined,
    multiple: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    inputClasses: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      selectValue: this.selected,
    };
  },
  watch: {
    selectValue(val) {
      // console.log('select value changed: ', val);
      this.$emit('input', val);
    },
    selected(val) {
      // console.log(val, 'val');
      this.selectValue = val;
    },
  },
  computed: {
    showError() {
      return this.error.length > 0;
    },
  },
};
</script>

<style>

</style>
