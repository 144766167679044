import store from '../../store';

const meta = {
  requiresLogin: true,
  requiresUserType: store.state.User.accountTypes.staff,
};

export default [
  {
    path: 'matches',
    name: 'matches',
    component() {
      return import(/* webpackChunkName: "staffviews" */ '../../views/Matches/MatchesView.vue');
    },
    meta,
  },
  {
    path: 'matches/create',
    name: 'createMatch',
    props: true,
    component() {
      return import(/* webpackChunkName: "staffviews" */ '../../views/Matches/MatchesView.vue');
    },
    meta,
  },
];
