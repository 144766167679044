import { getReadableFileType, toBoolean } from './dataHelper';

const passwordRegex = /^((.){8,})$/i;
const phoneNumberRegex = /^(07(\d){8})$/g;

function isValidTextInput(value, strict = true, regex = /\w+/) {
  if (strict === true && !toBoolean(value)) {
    return false;
  }

  const reg = new RegExp(regex);

  if (reg.test(value) === true || strict === false) {
    return true;
  }
  return false;
}

function isValidNumber(value, strictPattern = true) {
  const reg = new RegExp(strictPattern ? phoneNumberRegex : /\d+/g);
  //
  const is = reg.test(value);

  return is;
}

function isValidEmail(email) {
  const emailStr = email || '';
  const emailRegexTest = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g
    .test(emailStr);

  return !!emailRegexTest;
}

// eslint-disable-next-line no-useless-escape
const urlRegex = /(https:\/\/)+(?:[a-zA-Z0-9@:%._\+~#=-]{2,256}\.[a-z]{2,7}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*))/ig;
// eslint-disable-next-line no-useless-escape
const urlRegexNoProtocol = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,7}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig;

function isValidUrl(url, noProtocol = false) {
  const reg = new RegExp(noProtocol ? urlNoProtocol : urlRegex);

  return reg.test(url);
}

function isValidFile(file) {
  if (typeof file === 'object') {
    return !!getReadableFileType(file.type);
  }
  return !!getReadableFileType(file);
}

function isValidSelectInput(value) {
  if (Array.isArray(value)) {
    return value.every((v) => v !== undefined);
  }

  return value !== undefined;
}

function passwordsMatch(password1, password2) {
  return password1 === password2 && isValidTextInput(password1, true, passwordRegex);
}

function isValidForm(form) {
  console.log('validating form: ', form);
  return true;
}

export default {
  isValidTextInput,
  isValidSelectInput,
  isValidEmail,
  isValidUrl,
  isValidNumber,
  passwordsMatch,
  isValidFile,
  isValidForm,
  passwordRegex,
};
