export default {
  state: {
    uid: undefined,
    landingpage: {
      text: '',
      bgImage: undefined,
      // colorTheme: undefined, // * ICEBOX
    },
    uiSettings: {},
  },
};
