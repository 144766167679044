import _debounce from 'lodash/debounce';
import { apiStore, apiPost } from '../../../modules/apiHelper';
import { isStaleData, now } from '../../../modules/dataHelper';

export default {
  updateUserSession(context, [index, data]) {
    apiStore.setItem(index, data);
  },
  async clearUserSession(context) {
    await apiStore.clear().then(() => {
      sessionStorage.clear();
      console.log('Database is now empty.');
    }).catch((err) => {
      console.log('Can\'t clear database', err);
    }).finally(() => {
      context.commit('clearUserSession');
    });
  },
  userLogIn(context, userData) {
    apiStore.setItem('userid', userData.userid);
    apiStore.setItem('userurl', userData.url);
    apiStore.setItem('usertype', Number(userData.level) || Number(userData.type) || undefined);
    apiStore.setItem('licensetype', userData.licensetype);
    apiStore.setItem('companyid', userData.companyid);
    apiStore.setItem('session-expiry', new Date().getTime());
    context.commit('userLogIn', userData);
    context.dispatch('postUserLastSeen');
  },
  async userLogOut(context, authError = undefined) {
    // clear session storage
    await context.dispatch('postUserLastSeen');
    // console.log('logging out...');

    const formData = new FormData();
    formData.set('userid', context.state.id);
    formData.set('user_url', context.state.url);

    await apiPost('logout', formData, 1);

    // clear session API token (from localStorage)
    await context.dispatch('clearUserSession');
    await context.commit('authError', authError);

    return true;
  },

  // -----------------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------------------

  /**
   *
   * @returns {Array} [userid, userurl, username, usertype, licensetype, companyid, userTypeName]
   */
  async getLoggedInUserData({
    state,
    getters,
    commit,
    dispatch,
  }) {
    const details = await Promise.all([
      state.id || apiStore.getItem('userid'), // 0
      state.url || apiStore.getItem('userurl'), // 1
      state.name || apiStore.getItem('username'), // 2
      state.type || apiStore.getItem('usertype'), // 3
      state.licensetype || apiStore.getItem('licensetype'), // 4
      state.companyid || apiStore.getItem('companyid'), // 5
    ]);
    // whether 'staff' or 'superAdmin'
    details[6] = getters.userTypeName;

    // console.log(details);

    // update the store to match the localDB
    commit('updateUser', ['id', details[0]]);
    commit('updateUser', ['url', details[1]]);
    commit('updateUser', ['name', details[2]]);
    commit('updateUser', ['type', details[3]]);
    commit('updateUser', ['licensetype', details[4]]);
    commit('updateUser', ['companyid', details[5]]);

    if (state.id) {
      // confirm from db the user type, if there's a mismatch, logout with authError
      await dispatch('logUser', false);
    }

    return details;
  },

  /**
   * MOST BASIC ACTION
   * This confirms the right user is logged in
   *    else logs session out
   */
  logUser: _debounce(async ({
    state,
    dispatch,
    commit,
  }, strict = false) => {
    if (Number(process.env.VUE_APP_STATIC_USER)) {
      console.info('Static user...');
      return true;
    }

    // console.info('Confirming user...');
    const formData = new FormData();
    formData.set('userid', state.id);

    return apiPost('log_user', formData, 5)
      .then(async (res) => {
        if (!res) return false;
        if (!(res.data || {}).log_user) return false;

        if (!res.data.log_user.length) {
          await dispatch('userLogOut',
            'Error in your log in details. Please log in again.');
          console.warn('user data not returned');
          return false;
        }

        const user = res.data.log_user[0];
        const expiry = await apiStore.getItem('session-expiry') || now();

        // ! changed to use 'level' instead of 'type'
        if (Number(user.level) !== state.type || (isStaleData(expiry) && strict)) {
          console.warn('authError!');
          await dispatch('userLogOut',
            'Error in your log in details. Please log in again.');
          return false;
        }

        commit('updateUser', ['name', user.name]);
        await Promise.all([
          dispatch('updateUserSession', ['username', user.name]),
          dispatch('updateUserSession', ['userphoto', user.photo]),
          dispatch('updateUserSession', ['userbio', user.bio]),

          dispatch('userLogIn', {
            userid: user.id,
            url: user.u,
            licensetype: 1, // TODO: dynamic license type id
            type: user.level || user.type, //
            companyid: user.companyid,
          }),
        ]);

        return true;
      })
      .catch(() => {
        commit('xhrError', 'Sorry, an error occured. Check your internet connection.');
      });
  }, 700, {
    leading: true, // very important, otherwise you are logged out
  }),

};
