<template>
  <div class="tw-relative tw-flex"
    @click="cardClick($event)"
  >

    <div class="tw-shrink-0 tw-text-center tw-relative">
      <svg class="tw-max-w-lg" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
          y="0px" viewBox="0 0 72 72" style="enable-background:new 0 0 72 72; width: 72px;" xml:space="preserve">
        <path d="M62.1,14h-8.8c-0.8,0-1.6-0.6-1.6-1.6V2.6C52,1.2,50.8,0,49.4,0H12.1c-2.8,0-5,2.2-5,5v62c0,2.8,2.2,5,5,5h47.9
          c2.8,0,5-2.2,5-5V16.6C64.9,15.2,63.7,14,62.1,14z M60.9,66.8c0,0.6-0.4,1-1,1H12.1c-0.6,0-1-0.4-1-1V5c0-0.6,0.6-1,1-1H48v8.4
          c0,3,2.4,5.6,5.6,5.6h7.4V66.8z M51,29.9c0,1.2-0.8,2-2,2H23c-1.2,0-2-0.8-2-2s0.8-2,2-2H49C50.2,27.9,51,28.7,51,29.9z M51,41.9
          c0,1.2-0.8,2-2,2H23c-1.2,0-2-0.8-2-2c0-1.2,0.8-2,2-2H49C50.2,39.9,51,40.7,51,41.9z M51,53.9c0,1.2-0.8,2-2,2H23c-1.2,0-2-0.8-2-2
          s0.8-2,2-2H49C50.2,51.9,51,52.7,51,53.9z"/>
      </svg>
      <p style="top: 54px; transform: translateX(-50%);" class="tw-text-xs tw-leading-15 tw-px-3 o tw-uppercase tw-rounded-full tw-bg-black tw-text-white tw-left-1/2 tw--translate-x-1/2 tw-inline-block tw-absolute">
        {{ resourceType(resource.file_type) }}
      </p>
    </div>

    <div class="tw-shrink-1 tw-pt-2 tw-pl-2">
      <h4 style="max-height: 41px;" class="leading-19 tw-text-base tw-pb-2 tw-break-all tw-overflow-hidden">
        {{ resource.name || resource.data || resource.path }}
      </h4>
      <h6 class="opacity-31 tw-text-xs leading-15 tw-overflow-hidden tw-my-2 tw-mt-0" style="max-height: 30px" >
        Uploaded {{ (resource['Date of Post'] || resource['dateOfPost']) | moment('calendar') }}
      </h6>

      <div class="tw-self-stretch tw-flex-grow tw-w-full">
        <slot name="default" />
      </div>

      <div class="tw-flex tw-items-center tw-justify-between tw-mb-2">
        <div class="tw-inline-flex tw-items-center tw-bg-app-black-11-- tw-rounded-r-full tw-py-1" v-if="resource.sender">
          <UserPhoto
            :photo="resource.sender.photo"
            :user-name="resource.sender"
            photo-size="tw-h-6 tw-w-6"
            :title="resource.sender.name"
          />
        </div>
        <button
          class="leading-14 tw-p-1 tw-border-box tw-inline-block active:tw-bg-gray-300 focus:tw-bg-gray-300 tw-rounded-sm hover:tw-bg-gray-300 tw-fill-current"
          @click.stop="downloadResource(resource.data)"
        >
          <svg class="tw-w-6 tw-h-6" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
            y="0px" viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
            <path d="M64.8,69.1c0,1.7-1.2,2.9-2.9,2.9H10.1c-1.7,0-2.9-1.2-2.9-2.9c0-1.7,1.2-2.9,2.9-2.9h51.8C63.6,66.2,64.8,67.4,64.8,69.1z
              M61.6,30.5c-1.2-1.2-2.9-1.2-4,0L38.9,49.2V2.9C38.9,1.2,37.7,0,36,0s-2.9,1.2-2.9,2.9v46.4L14.4,30.5c-1.2-1.2-2.9-1.2-4,0
              c-1.2,1.2-1.2,2.9,0,4L34,58.2c0.3,0.3,0.6,0.6,0.9,0.6C35.1,59,35.7,59,36,59s0.9,0,1.2-0.3s0.6-0.3,0.9-0.6l23.6-23.6
              C62.8,33.4,62.8,31.7,61.6,30.5z"/>
          </svg>
        </button>
        <p class="opacity-54 tw-text-xs leading-15 tw-overflow-hidden tw-my-2 tw-mt-0 tw-bg-gray-300 tw-rounded-full tw-text-gray-900 tw-px-2" style="max-height: 30px" v-if="resource.shares" >
          {{ resource.shares }} users
        </p>

        <!-- <button
          v-if="canShare"
          class="leading-14 tw-p-1 tw-border-box tw-inline-block active:tw-bg-gray-300 focus:tw-bg-gray-300 tw-fill-current tw-relative"
          @click.stop="shareBtnClick($event)"
        >
          <span class="tw-absolute tw-left-0 tw-top-0 tw-w-full tw-h-full tw-z-10"></span>
          <span class="tw-inline-block tw-uppercase tw-font-bold tw-text-xs tw-align-middle">SHARE</span>
          <svg class="tw-w-6 tw-h-6 tw-ml-1 tw-inline-block" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
            <path d="M71.1,25.6L56.6,10.8c-1-1-2.4-1-3.3,0c-1,1-1,2.4,0,3.3l11.2,11.5H25.3C11.5,25.6,0,36.8,0,50.9v8.6c0,1.4,1,2.4,2.4,2.4
              s2.4-1,2.4-2.4v-8.6c0-11.2,9.1-20.5,20.5-20.5h39.1L53.2,41.8c-1,1-1,2.4,0,3.3c0.5,0.5,1,0.7,1.7,0.7c0.7,0,1.2-0.2,1.7-0.7
              l14.6-14.8C72.3,29.2,72.3,27.1,71.1,25.6z"/>
          </svg>
        </button> -->
      </div>
    </div>
  </div>
</template>

<script>
import UserPhoto from '@/components/UserPhoto.vue';
// import ResourceMixin from '@/mixins/resources';

export default {
  name: 'ResourceCard',
  components: {
    UserPhoto,
  },
  props: {
    resource: {
      type: Object,
      required: true,
    },
    canShare: {
      type: Boolean,
      default: false,
    },
  },
  // mixins: [ResourceMixin],
  methods: {
    cardClick($event) {
      this.$emit('click-card', $event, this.resource);
    },
    shareBtnClick($event) {
      this.$emit('click-share', $event, this.resource);
    },
    deleteBtnClick($event) {
      this.$emit('click-delete', $event, this.resource);
    },
  },
};
</script>

<style>

</style>
