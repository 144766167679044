import localforage from 'localforage';
import {
  isStaleData, now, toBoolean,
} from './dataHelper';
import xhrRequest from './xhr';

/**
 *
 * @param {Boolean} isProduction ask (if url is a production url, parse true)
 * @returns {Boolean}
 */
export function whichBuild(isProduction = true) {
  const url = window.location.hostname;
  const regexTest = new RegExp(isProduction ? process.env.VUE_APP_SCOPE_URL : process.env.VUE_APP_DEV_APP_URL);

  return !!(regexTest.test(url));
}

export const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
export const apiUrl = `${apiBaseUrl}${whichBuild(true) ? '' : '/test'}`;

export const apiTsdUrl = `${apiUrl}/tsd/`;
export const apiTslUrl = `${apiUrl}/tsl/`;
export const apiTslAccUrl = `${apiUrl}/tsl_account/`;
export const apiTslMssgUrl = `${apiUrl}/tsl_mssg/`;
export const apiTslOptUrl = `${apiUrl}/tsl_opt/`;
export const apiTslStatsUrl = `${apiUrl}/tsl_stats/`;
export const apiTslSettingsUrl = `${apiUrl}/tsl_settings/`;
export const apiProcessUrl = `${apiUrl}/process/`;
export const apiTstUrl = `${apiUrl}/tst/`;
export const apiTsvUrl = `${apiUrl}/tsv/`;
export const apiTaskUrl = `${apiUrl}/task/`;
export const apiChatUrl = `${apiUrl}/chat/`;
export const apiCreateUrl = `${apiUrl}/create/`;
export const apiSchlUrl = `${apiUrl}/schl/`;
export const apiPackUrl = `${apiUrl}/pack/`;

export const apiStore = localforage.createInstance({
  driver: [
    localforage.INDEXEDDB,
    localforage.WEBSQL,
    localforage.LOCALSTORAGE,
  ],
  name: 'ema-app',
  storeName: 'highdata',
  version: 3,
});

export function getlocalStoreArrayItem(key) {
  return apiStore.getItem(key).then((value) => JSON.parse(value));
}

/**
 * generate api endpoint url
 * @param {String} endpoint [tsd, tst, task, _custom_, chat, process, tsl, tsl_account, tsl_mssg, tsl_opt, tsl_stats, tsv, create, tsl_settings, schl, pack] default: tsd
 * @param {String} action
 */
export function buildApiEndpoint(action, endpoint) {
  switch (endpoint) {
    case 0:
      return `${apiTsdUrl}?action=${action}`;
    case 1:
      return `${apiTstUrl}?action=${action}`;
    case 2:
      return `${apiTaskUrl}?action=${action}`;
    case 3:
      return `${apiUrl}/${action}/`;
    case 4:
      return `${apiChatUrl}?action=${action}`;
    case 5:
      return `${apiProcessUrl}?action=${action}`;
    case 6:
      return `${apiTslUrl}?action=${action}`;
    case 7:
      return `${apiTslAccUrl}?action=${action}`;
    case 8:
      return `${apiTslMssgUrl}?action=${action}`;
    case 9:
      return `${apiTslOptUrl}?action=${action}`;
    case 10:
      return `${apiTslStatsUrl}?action=${action}`;
    case 11:
      return `${apiTsvUrl}?action=${action}`;
    case 12:
      return `${apiCreateUrl}?action=${action}`;
    case 13:
      return `${apiTslSettingsUrl}?action=${action}`;
    case 14:
      return `${apiSchlUrl}?action=${action}`;
    case 15:
      return `${apiPackUrl}?action=${action}`;
    default:
      return `${apiTsdUrl}?action=${action}`;
  }
}

/**
 * #### make an ajax GET request (uses xhr.js)
 * @param {String} actionParam api action name
 * @param {Number} endpoint [tsd, tst, task, _custom_, chat, process, tsl, tsl_account, tsl_mssg, tsl_opt, tsl_stats, tsv, create, tsl_settings, schl, pack] default: tsd
 * @param {Object} extraParam request queries {key: value} -> ?[key]=[value]&...
 * @param {Boolean} refresh whether to bypass cached results/fetch from server default: false
 * @param {Boolean} cacheData whether to cache the data default: true
 */
// eslint-disable-next-line consistent-return
export async function apiGet(
  actionParam = '',
  endpoint = 0,
  extraParam = {},
  refresh = false,
  cacheData = true,
) {
  try {
    const storeName = `${endpoint}/${actionParam}/${JSON.stringify(extraParam)
      .slice(0, 1)
      .slice(-1, -2)
      .replace('-', '_')
      .replace(' ', '_')}`;
    const storeData = (await apiStore.getItem(storeName) || undefined);

    if (Array.isArray(storeData) && !refresh) {
      if (!isStaleData(storeData[0])) return storeData[1];
    } else {
      // clean api cache
      await apiStore.removeItem(storeName);
    }

    const url = buildApiEndpoint(actionParam, endpoint);
    if (!url) throw new Error('BAD GET API URL');

    return new Promise((resolve, reject) => {
      xhrRequest({
        url,
        method: 'GET',
        params: {
          ...extraParam,
        },
      }).catch((err) => {
        console.warn('AJAX GET ERROR: ', actionParam);
        reject(err);
      }).then((res) => {
        // validate and save the data
        if (typeof res === 'object') {
          // eslint-disable-next-line no-param-reassign
          res.data.error = toBoolean(res.data.error);
          // eslint-disable-next-line no-param-reassign
          delete res.config;
          // eslint-disable-next-line no-param-reassign
          delete res.request;

          if (cacheData) {
            apiStore.setItem(storeName, [now(), res]);
          }
        }

        resolve(res);
      });
    });
  } catch (err) {
    console.warn(err);
  }
}

/**
 * Make an ajax POST request. (uses .xhr.js)
 * @param {String} action api action name
 * @param {FormData} data
 * @param {Number} endpoint [tsd, tst, task, _custom_, chat, process, tsl, tsl_account, tsl_mssg, tsl_opt, tsl_stats, tsv, create, tsl_settings, schl, pack] default: tsd
 * @param {Object} headers
 * @param {Function} progressCallback xhr.onprogress event handler
 */
// eslint-disable-next-line consistent-return
export async function apiPost(
  action,
  data,
  endpoint = 0,
  headers = { 'Content-Type': 'multipart/form-data' },
  progressCallback = undefined,
) {
  if (!action || !data) {
    console.error('action or data not set');
    return false;
  }

  try {
    const url = buildApiEndpoint(action, endpoint);

    if (!url) throw new Error('BAD POST API URL', url, action, '\n');

    return xhrRequest({
      url,
      method: 'POST',
      data,
      headers,
      onUploadProgress: progressCallback,
    }).catch(() => {
      console.warn('AJAX POST ERROR: ', action);
    }).then((res) => {
      if (typeof res === 'object') {
        // make 'error' property a real boolean
        // eslint-disable-next-line no-param-reassign
        res.data.error = toBoolean(res.data.error);
      }

      return res;
    });
  } catch (err) {
    console.warn(err);
  }
}

/**
 * Does not support files YET
*/
export function generateFormData(obj = {}) {
  const formData = new FormData();

  Object.entries(obj).forEach(([index, value]) => {
    if (typeof value !== 'undefined' || value !== 'null') {
      formData.set(index, value);
    }
  });

  return formData;
}
