import { capitalizeText } from '../modules/dataHelper';

export default {
  name: 'userAppMixin',
  methods: {
    async registerStoreModule(storeName, storeModule) {
      const store = this.$store;
      // console.log('registering module...');

      if (!(store && store.state[storeName])) {
        await store.registerModule(storeName, storeModule);
      }
    },
    unregisterStoreModule(storeName) {
      // eslint-disable-next-line no-underscore-dangle
      const storeModule = this.$store._modules.root;
      // eslint-disable-next-line no-underscore-dangle
      const storeMutations = this.$store._mutations;
      // eslint-disable-next-line no-underscore-dangle
      // console.log('STORE NAME', storeName, storeModule._children[storeName]);

      // eslint-disable-next-line no-underscore-dangle
      if (storeModule._children[storeName]) {
        // * clear all store type data
        const mutationFnName = `clear${capitalizeText(storeName)}Data`;
        const mutationFnName2 = `delete${capitalizeText(storeName)}Data`;
        if (mutationFnName in storeMutations || mutationFnName2 in storeMutations) {
          this.$store.commit(mutationFnName);
        }

        this.$store.unregisterModule(storeName);
      }
    },
    async logout(authError = undefined) {
      this.$toasted.info('Logging you out..', {
        duration: 4500,
      });

      await this.$store.dispatch('userLogOut', authError)
        .then(async () => {
          if (this.$route.name !== 'login') {
            await this.$router.push({ name: 'login' });

            await this.$nextTick();

            Object.keys(this.storeModulesNames).forEach(async (storeName) => {
              await this.unregisterStoreModule(storeName);
            });
          }
        });
    },
  },
};
