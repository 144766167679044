var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'app-view tw-flex tw-flex-wrap tw-relative transition',

    {
      'app-view--max': _vm.minimalNav,
      'app-view--full': _vm.fullViewApp
    },

    { 'app-view--with-menu': _vm.hasNavFixed } ]},[(_vm.nav === 'left-sticky' || _vm.nav === 'left-fixed')?_c('div',{class:[
      'app-view-nav tw-min-h-screen transition tw-z-20',
      ("" + _vm.navClass),
      { 'app-view-nav--menu-minimal' : _vm.minimalNav },
      {
        'tw-fixed tw-left-0' : _vm.nav === 'left-fixed',
        'tw-sticky' : _vm.nav === 'left-sticky'
      } ]},[_vm._t("nav")],2):_vm._e(),_c('div',{ref:"top-app-view",staticClass:"tw-fixed tw-top-0 tw-z-10 tw-w-full tw-bg-app-light-blue"},[_c('transition',{attrs:{"name":"fade","mode":"out-in","appear":""}},[_c('portal-target',{attrs:{"name":"top-app-view"}},[_vm._t("top-app-view",null,{"recalculate":_vm.calculatePaddings})],2)],1)],1),_c('div',{class:['tw-z-0 tw-relative tw-min-h-screen tw-flex-grow', _vm.bodyClass],style:({
      paddingTop: (_vm.topViewHeight + "px"),
      paddingBottom: _vm.paddBottom ? (_vm.bottomViewHeight + "px") : 'auto',
    })},[_vm._t("default",null,{"recalculate":_vm.calculatePaddings})],2),_c('div',{ref:"bottom-app-view",staticClass:"tw-fixed tw-bottom-0 tw-z-10 tw-w-full bg-bottom-app transition-fast border-box",style:(("transform: translateY(" + (_vm.showBottomBar ? '0%' : '100%') + ")"))},[_vm._t("bottom-app-view"),_c('div',{class:{ 'tw-absolute tw-bottom-0 tw-w-full': _vm.floatBottomAppView }},[_c('transition',{attrs:{"name":"new","mode":"out-in","appear":""}},[_c('portal-target',{attrs:{"name":"bottom-app-view"}})],1)],1)],2)])}
var staticRenderFns = []

export { render, staticRenderFns }