import store from '../../store';

import EditCompanyGoalsView from '../../views/Companies/Company/EditCompanyGoalsView.vue';
import CustomizeCompanyView from '../../views/Companies/Company/CustomizeCompanyView.vue';

const companiesView = import(/* webpackChunkName: "sadminviews" */ '../../views/Companies/CompaniesView.vue');
const companyView = import(/* webpackChunkName: "staffviews" */ '../../views/Companies/Company/CompanyView.vue');

const metaSuperAdmin = {
  requiresLogin: true,
  requiresUserType: store.state.User.accountTypes.superAdmin,
};

const metaStaff = {
  requiresLogin: true,
  requiresUserType: store.state.User.accountTypes.staff,
};

export default {
  superAdmin: [
    {
      path: 'companies',
      name: 'companies',
      component() {
        return companiesView;
      },
      meta: metaSuperAdmin,
    },
    {
      path: 'companies/create',
      name: 'createCompany',
      component() {
        return companiesView;
      },
      meta: metaSuperAdmin,
      props: {
        create: true,
      },
    },
    {
      path: 'companies/edit/:id',
      name: 'viewCompany',
      component() {
        return companyView;
      },
      meta: metaSuperAdmin,
      props: true,
    },
    {
      path: 'companies/edit/:id/goals',
      name: 'editCompanyGoals',
      component: EditCompanyGoalsView,
      meta: metaSuperAdmin,
      props: true,
    },
    {
      path: 'companies/edit/:id/customize',
      name: 'customizeCompany',
      component: CustomizeCompanyView,
      meta: metaSuperAdmin,
      props: true,
    },
  ],
  staff: [
    {
      path: 'my-license',
      name: 'myLicense',
      component() {
        return companyView;
      },
      meta: metaStaff,
    },
    {
      path: 'my-license/mentorship/goals',
      name: 'editCompanyGoals',
      component: EditCompanyGoalsView,
      meta: metaStaff,
      props: true,
    },
    {
      path: 'my-license/mentorship/customize-experience',
      name: 'customizeCompany',
      component: CustomizeCompanyView,
      meta: metaStaff,
      props: true,
    },
  ],
};
