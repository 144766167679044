import Vue from 'vue';

import Toasted from 'vue-toasted/dist/vue-toasted';
import PortalVue from 'portal-vue';
import vueMoment from 'vue-moment';
import vSelect from './modules/vSelect';

import router from './router';
import store from './store';
import uiMixins from './mixins/uiMixins';

import BaseButton from './components/BaseButton.vue';
import BaseTextarea from './components/BaseTextarea.vue';
import BaseCard from './components/BaseCard.vue';
import ViewMore from './components/ViewMore.vue';
import AppView from './views/_partials/AppView.vue';
import TopNav from './views/_partials/TopNav.vue';

import { firstLetter, toJsDate } from './modules/dataHelper';

import App from './App.vue';
import './registerServiceWorker';


// #region global register

Vue.filter('firstLetter', firstLetter);
Vue.filter('toJsDate', toJsDate);

Vue.mixin(uiMixins);

Vue.use(Toasted);
Vue.use(PortalVue);
Vue.use(vueMoment);

Vue.component('AppView', AppView);
Vue.component('BaseButton', BaseButton);
Vue.component('BaseCard', BaseCard);
Vue.component('BaseTextarea', BaseTextarea);
Vue.component('TopNav', TopNav);
Vue.component('ViewMore', ViewMore);
Vue.component('v-select', vSelect);

Vue.toasted.register('appError',
  (payload) => {
    // if there is no message passed show default message
    if (!payload.errorMessage) {
      return 'Oops.. Something Went Wrong';
    }

    // if there is a message show it with the message
    return `Oops... ${payload.errorMessage}`;
  }, {
    position: 'top-right',
    fullWidth: false,
    type: 'error',
    duration: 4000,
  });

// #endregion

Vue.config.productionTip = process.env.NODE_ENV !== 'production';

// TODO: create a non-reactive loading state
(async () => {
  const $router = await router();

  new Vue({
    router: $router,
    store,
    render: (h) => h(App),
  }).$mount('#app');
})();
