import store from '../../store';

import stats from './stats';
import accounts from './accounts';
import matches from './matches';
import resources from './resources';
import licenses from './licenses';
import classes from './classes';
import packages from './packages';
import helpline from './helpline';

import Dashboard from '../../views/Dashboard.vue';

const userData = store.dispatch('getLoggedInUserData');

const UserRoutes = async () => {
  await userData;

  // add super admin routes if user is super admin
  let adminRoutes = [];
  if (store.state.User.type) {
    // eslint-disable-next-line eqeqeq
    if (store.state.User.type == store.state.User.accountTypes.superAdmin) {
      adminRoutes = [
        ...licenses.superAdmin,
        ...classes,
        ...packages,
      ];
    } else {
      adminRoutes = [
        ...licenses.staff,
      ];
    }
  }

  return [{
    path: '/app',
    component: Dashboard,
    name: 'app',
    meta: {
      requiresLogin: true,
      requiresUserType: store.state.User.accountTypes.staff,
    },
    children: [
      ...stats,
      ...accounts,
      ...matches,
      ...resources,
      ...helpline,
      ...adminRoutes,
    ],
  }];
};

export default UserRoutes;
