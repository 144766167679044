import { apiUrl } from '../../modules/apiHelper';
import { urlFriendly } from '../../modules/dataHelper';

export default {
  name: 'userUiMixin',
  computed: {
    isSuperAdmin() {
      return this.$store.state.User.type === this.$store.getters.adminUserTypes.superAdmin;
    },
    isOtherAdmin() {
      return this.$store.state.User.type === this.$store.getters.adminUserTypes.staff;
    },
  },
  methods: {
    userPhoto(url = '') {
      return url.length > 0 ? `${apiUrl}/${urlFriendly(url, true)}` : '';
    },
  },
};
