import Vue from 'vue';
import Vuex from 'vuex';

import Ui from './Ui';
import User from './User';
import Reports from './Reports';
import CompanySettings from './CompanySettings';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    Ui,
    User,
    Reports,
    CompanySettings,
  },
});


export default store;
