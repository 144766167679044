import store from '../../store';
import StatsView from '../../views/Stats/StatsView.vue';

const meta = {
  requiresLogin: true,
  requiresUserType: store.state.User.accountTypes.staff,
};

export default [
  {
    path: 'stats',
    // alias: 'default', // to be the default view loaded by route "/app"
    name: 'stats',
    component: StatsView,
    meta,
    children: [

      {
        path: 'accounts',
        alias: 'default',
        name: 'accStats',
        component() {
          return import(/* webpackChunkName: "stats" */ '../../views/Stats/AccountsStats.vue');
        },
      },
      {
        path: 'goals',
        name: 'goaStats',
        component() {
          return import(/* webpackChunkName: "stats" */ '../../views/Stats/GoalsStats.vue');
        },
      },
      {
        path: 'matches',
        name: 'matStats',
        component() {
          return import(/* webpackChunkName: "stats" */ '../../views/Stats/MatchesStats.vue');
        },
      },
      // {
      //   path: 'resources',
      //   name: 'resStats',
      //   component() {
      //     return import(/* webpackChunkName: "stats" */ '../../views/Stats/ResourcesStats.vue');
      //   },
      // },

    ],
  },
];
