<template>
  <AppView
    padd-bottom
  >
    <template v-slot:top-app-view>
      <transition name="fade" mode="out-in" appear>
        <TopNav
          title="Resources"
          title-class="font-title pl-body tw-text-xl tw-tracking-wider tw-leading-6 opacity-54"
        >
          <BaseButton
            text="Share Resource"
            slot="right-actions"
            class="tw-bg-app-deep-blue tw-border-none tw-py-3 tw-px-6 tw-text-white tw-shadow-app hover:tw-shadow-app-lg transition-fast mx-body"
            @click="openUploadModal()"
          />
        </TopNav>
      </transition>
    </template>

    <!-- Upload form modal -->
    <portal to="modal-body">
      <div class="tw-w-full tw-p-4 tw-pt-6 tw-overflow-auto">
        <ShareResources
          class="tw-mx-auto"
          @resource-shared="resourceShared()"
        />
      </div>
    </portal>

    <div class="app-view-grid px-body tw-py-4">
      <div class="app-view-grid__col-74">
        <!-- FILTER COMPONENT -->
        <BaseCard class="tw-flex tw-flex-no-wrap tw-py-2 tw-px-4 tw-items-center tw-border-t-2 tw-border-app-white-87 tw-transition-slow">
          <div class="tw-inline-flex tw-items-center">
            <!--  -->
            <svg version="1.1" class="tw-w-6 tw-h-6" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
              <path d="M32.2,72c-0.7,0-1.6-0.2-2.3-0.7c-1.1-0.7-1.8-2.1-1.8-3.4V43.7L7.8,5.7C7.2,4.3,7.4,3,8.1,1.8C8.7,0.7,9.9,0,11.5,0h49
                c1.4,0,2.7,0.7,3.4,1.8s0.9,2.5,0.5,3.9L45.7,43.7v17.8c0,1.6-0.9,3-2.3,3.6l-9.6,6.4C33.4,71.8,32.7,72,32.2,72z M12.2,4.6
                l20.1,37.4c0.2,0.7,0.5,1.1,0.5,1.8V67l8.4-5.7V43.5c0-0.5,0-1.1,0.2-1.6L59.6,4.3H12.2V4.6z"/>
            </svg>
            <p class="leading-19 tw-ml-2">Filter</p>
          </div>
          <BaseInput
            v-model="filterConfig.search"
            type="text"
            placeholder="Search"
            input-class="tw-ml-8 tw-bg-app-deep-blue-11 tw-rounded-3 tw-text-sm leading-17 tw-p-2 tw-flex-grow tw-min-w-1/4"
          />
          <v-select
            placeholder="File Type"
            class="my-select tw-w-40 tw-ml-10 tw-bg-app-deep-blue-11 tw-border-b tw-border-black tw-text-black tw-rounded-t-3 tw-rounded-b-none tw-text-sm tw-flex-shrink-0"
            v-model="filterConfig.type"
            :options="filterConfig.options"
            clearable
          />
          <!-- <BaseButton
            text="Filter"
            class="tw-ml-8 tw-bg-app-black-11 hover:tw-bg-blue-200 hover:tw-shadow-app transition-fast tw-py-3 tw-px-10 tw-flex-shrink-0 tw-border-none tw-flex-grow-0"
            @click="doFilter()"
          /> -->
        </BaseCard>

        <!-- RESOURCES -->
        <transition name="fade" mode="out-in" appear>

          <div v-if="errorResources" class="tw-mt-12">
            <p class="tw-py-12 tw-text-base tw-text-center" v-html="errorResources"></p>
          </div>

          <ContentLoadingThreeGrid
            v-else-if="loadingResources"
            class="tw-mt-16 -tw-max-w-list tw-mx-auto"
          />

          <template v-else>

            <div v-if="filteredResources.length === 0 && hasFiltered" class="tw-mt-12">
              <!-- Goodies -->
              <p class="tw-py-16 tw-text-base tw-text-center opacity-54">There are 0 {{ filterConfig.type }} resources</p>
            </div>

            <div v-else-if="filteredResources.length === 0" class="tw-mt-12">
              <!-- Goodies -->
              <p class="tw-py-16 tw-text-base tw-text-center opacity-54">Use the filter bar to search for resources!</p>
            </div>

            <ViewMore
              v-else
              class="tw-flex tw-flex-wrap stacked-grid lg:stacked-grid--stack-three stacked-grid--stack-three tw-mt-12"
              :list="filteredResources"
              :limit="20"
            >

              <template v-slot="{ item: resource, index }">
                <!-- RESOURCE CARD -->
                <ResourceCard
                  :key="`res-${index}`"
                  :resource="resourceCardData(resource)"
                >
                  <template v-slot:default v-if="resource.sender || resource.company">
                    <div v-if="resource.company" class="tw-my-1 tw-p-2 tw-bg-app-black-11">
                      <p class="tw-text-xs opacity-54 leading-15 tw-uppercase tw-font-bold">
                        Company
                      </p>
                      <p class="tw-text-sm leading-15">
                        {{ resource.company.company }}
                      </p>
                    </div>

                    <div v-if="resource.sender" class="tw-my-1">
                      <!-- <div class="tw-inline-flex tw-items-center tw-bg-app-black-11-- tw-rounded-r-full tw-py-1">
                        <UserPhoto
                          :photo="resource.sender.photo"
                          :user-name="resource.sender"
                          photo-size="tw-h-6 tw-w-6"
                        />
                        <p class="tw-text-sm tw-ml-2 leading-15 opacity-78">
                          {{ resource.sender.name }}
                        </p>
                      </div> -->
                    </div>
                  </template>
                </ResourceCard>
              </template>

            </ViewMore>

          </template>

        </transition>
      </div>
      <div></div>
    </div>
  </AppView>
</template>

<script>
import ShareResources from '@/views/Resources/_partials/ShareResources.vue';
import BaseInput from '@/components/BaseInput.vue';
import ResourceCard from '@/components/ResourceCard2.vue';
import ContentLoadingThreeGrid from '@/components/ContentLoadingThreeGrid.vue';
// import UserPhoto from '@/components/UserPhoto.vue';
import filterMixin from '@/mixins/filter';
import { getReadableFileType } from '@/modules/dataHelper';
import { filter, sortBy, reverse } from 'lodash';

export default {
  name: 'ResourcesView',
  components: {
    ShareResources,
    BaseInput,
    ResourceCard,
    ContentLoadingThreeGrid,
    // UserPhoto,
  },
  mixins: [filterMixin],
  data() {
    return {
      resources: [],
      loadingResources: true,
      errorResources: undefined,
      filterConfig: {
        search: '',
        searchAt: [
          'path',
          'resource',
          'name',
        ],
        dataName: 'resources',
        type: '',
        options: [],
      },
    };
  },
  computed: {
    filteredResources() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.filterConfig.type = this.filterConfig.type || '';

      if (!this.filterConfig.type.length) return this.filteredList;

      return this.filteredList.filter((item) => {
        const itemFileType = getReadableFileType(item.file_type || item.resource || item.path, true);
        return (itemFileType.toLowerCase() === this.filterConfig.type) ? item : null;
      });
    },
    hasFiltered() {
      return this.filterConfig.type.length > 0 || this.filterConfig.search.length > 0;
    },
  },
  methods: {
    async getResources() {
      this.loadingResources = true;
      this.errorResources = undefined;
      await this.$nextTick();
      //

      const resources = await this.$store.dispatch('getResources');

      if (!resources) {
        this.$toasted.global.appError();
        await this.$nextTick();
        this.loadingResources = false;
        this.errorResources = 'We could not get shared resources. Please try again later';

        return false;
      }

      await this.$nextTick();
      const resourcesShares = {};
      // eslint-disable-next-line no-underscore-dangle
      const _resources = [];

      resources.forEach((res) => {
        if (resourcesShares[res.path]) return;
        resourcesShares[res.path] = res;

        // count
        const shares = filter(resources, ((r) => r.path === res.path)).length;

        _resources.push({ ...res, shares });
      });


      this.resources = reverse(sortBy(_resources, ['Date of Post', 'dateOfPost']));

      await this.generateFilterOptions();

      await this.$nextTick();
      this.errorResources = undefined;
      this.loadingResources = false;

      return true;
    },

    resourceCardData(resource = {}) {
      const fileType = getReadableFileType(resource.resource || resource.path, true);
      const resourceName = ((resource.resource || resource.path).split('/').pop()).split('.')[0];

      return {
        ...resource,
        ...{
          id: resource.resourceid,
          name: resourceName,
          file_type: fileType,
          data: resource.resource || resource.path,
        },
      };
    },

    async resourceShared() {
      // close modal then get resources
      this.closeModal();
      await this.getResources();
    },

    generateFilterOptions() {
      const types = [];

      this.resources.forEach((resource) => {
        const fileType = getReadableFileType(resource.file_type || resource.resource || resource.path, true);

        if (types.indexOf(fileType.toLowerCase()) < 0) {
          types.push(fileType.toLowerCase());
        }
      });

      this.filterConfig.options = types;
    },

    openUploadModal() {
      this.$store.commit('openModal', [true]);
    },
  },
  async created() {
    // GET Resources
    await Promise.all([
      this.getResources(),
    ]);
    await this.generateFilterOptions();


    await this.$nextTick();
    await this.$nextTick();

    this.loadingResources = false;
  },
};
</script>

<style>

</style>
