export default {
  name: 'reportMixin',
  // ? watch for completed reports
  // ? needs a dynamic watch
  data() {
    return {
      reportType: undefined,
      reportName: undefined,
      reportData: [],
    };
  },
  computed: {
    currentReports() {
      return this.$store.state.Reports.currentReports;
    },
  },
  methods: {
    findReportStatus(reportType = this.reportType) {
      // * reportTypeId
      return this.currentReports[reportType] || false;
    },

    async createReport(
      reportData = (this.reportData || []),
      reportType = this.reportType,
      reportName = this.reportName,
    ) {
      // find if there is an existing reportType action
      // eslint-disable-next-line no-underscore-dangle
      if (!(reportType in this.$store._actions)) {
        this.$toasted.global.appError();
        console.error(`action [${reportType}] does not exist!`);
        return false;
      }

      this.$toasted.info(
        'Exporting a report for you in the background. This might take upto 1 minute',
        {
          duration: 10000,
        },
      );

      await this.$nextTick();
      await this.$nextTick();

      // console.log(reportData, reportName);

      setTimeout(() => {
        this.$store.dispatch(reportType, [reportData, reportName]);
      }, 2000);

      return true;
    },

    /**
     * converts html to canvas then pass to reportType
     * ? How to pass multiple routed html
     */
    async createHTMLReport(
      element = (this.reportData || []),
      reportType = this.reportType,
      reportName = this.reportName,
    ) {
      // ! html to pdf

      // const htmlString = element.innerHTML;
      console.log(typeof element, reportType, reportName, '\n');
    },
  },
};
