<template>
  <AppView
    padd-bottom
  >

    <template v-slot:top-app-view>
      <transition name="fade" mode="out-in" appear>
        <TopNav
          type="back"
          :prev-route="{ name: (isSuperAdmin ? 'viewCompany' : 'myLicense'), params: { id: $route.params.id } }"
          :title="company.company || 'fetching company...'"
          :title-class="[
            'tw-ml-8',
            { 'opacity-78 font-title tw-text-xl tw-tracking-wider tw-leading-6': !loadingCompany },
            { 'opacity-31 tw-italic tw-lowercase': loadingCompany },
          ]"
        >
          <template v-slot:title-left v-if="!loadingCompany">
            <UserPhoto
              :photo="company.logo"
              :user-name="{
                name: company.company,
              }"
              logo
              photo-size="tw-h-12 tw-w-12"
              :class="[
                'tw-mr-6 tw-my-1 tw-rounded-10--force --force-children tw-bg-app-white-87',
                { 'tw-shadow-app-sm': !(company.logo || '').length }
              ]"
            />
          </template>
        </TopNav>
      </transition>
    </template>

    <transition name="fade" mode="out-in" appear>

      <div v-if="errorCompany" class="tw-mt-12" key="errmsg">
        <p class="tw-py-12 tw-text-base tw-text-center" v-html="errorCompany"></p>
      </div>

      <div v-else-if="loadingCompany" class="tw-mt-12" key="lodng">
        <p class="tw-py-12 tw-text-base tw-text-center">Loading...</p>
      </div>

      <div v-else key="bdy">

        <transition name="fade" mode="out-in" appear>

          <div class="app-view-grid px-body">
            <div class="app-view-grid__col-74">
              <portal to="modal-body" key="mdl">
                <transition
                  name="fade"
                  mode="out-in"
                  appear
                  :key="activeFormComponent"
                >
                  <component
                    :is="activeFormComponent"
                    v-bind="activeFormComponentOptions.props"
                    :class="['tw-p-6']"
                    @created-success="closeModal(); getCompany(); getCompanyGoals();"
                  />
                </transition>
              </portal>

              <template>
                <div class="tw-flex tw-items-center tw-pt-10 tw-pb-4">
                  <img
                    data-src="/img/edit-goals.png"
                    src="/img/edit-goals.png"
                    style="width: 80px; height: 80px;"
                    class="tw-object-cover tw-block tw-mr-8"
                    async
                  >
                  <h3 class="font-title tw-text-xl tw-tracking-wider">
                    Goals
                  </h3>
                </div>
                <div class="tw-border-t tw-border-app-black-11 tw-mb-16"></div>
                <div></div>
              </template>

              <!-- PILLAR GROUPS -->
              <template>

                <div class="tw-flex tw-items-center tw-justify-between tw-mt-8 tw-mb-3">
                  <h5 class="tw-text-sm tw-leading-tight tw-capitalize">Pillar Groups</h5>

                  <BaseButton
                    text="Add"
                    title="Create a Pillar Group"
                    @click="editGoalType('pillarGroups', getGoalTypeComponentProps('pillarGroups'))"
                  />

                </div>

                <ViewMore
                  class="tw-flex tw-flex-wrap stacked-grid stacked-grid--stack-four lg:stacked-grid--stack-four"
                  :list="companyGoals.pillarGroups.data"
                  :limit="4"
                  v-slot="{ item: pillarGroup }"
                >
                  <div class="info-card tw-group tw-shadow-app tw-relative tw-flex tw-flex-col tw-justify-between">
                    <h4 class="tw-leading-tight tw-font-medium opacity-87 tw-text-base tw-px-2 tw-py-4 tw-break-words">{{ pillarGroup.goal_group }}</h4>
                    <!-- <h6 class="opacity-31 tw-text-xs leading-15 tw-overflow-hidden tw-m-2 tw-mt-0" style="max-height: 30px" >
                      {{ subGoal.description }}
                    </h6> -->
                    <div class="tw-flex tw-items-end tw-mt-3">
                      <div class="tw-flex-1">
                        <!-- if deletable -->
                        <BaseButton
                          icon
                          text=""
                          confirm
                          v-if="canDeleteGoalType(pillarGroup.id, 'goals', 'groupid')"
                          @click="deleteGoalType('pillarGroups', pillarGroup.id)"
                          class="tw-border-none tw-bg-red-100 group-hover:opacity-78 hover:tw-opacity-100 opacity-11 tw-mb-2 tw-ml-2 tw-text-red-600 transition-slow"
                        >
                          <svg slot="icon" class="my-btn__icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                            y="0px" viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                            <path d="M63.1,15.2V64c0,4.4-3.6,8-8,8h-38c-4.4,0-8-3.6-8-8V15.2c0-1.1,0.8-1.9,1.9-1.9s1.9,0.8,1.9,1.9V64c0,2.3,1.9,4.2,4.2,4.2
                              h38c2.3,0,4.2-1.9,4.2-4.2V15.2c0-1.1,0.8-1.9,1.9-1.9S63.1,14.2,63.1,15.2z M66.3,4.9H38v-3C38,0.8,37.2,0,36.1,0
                              c-1.1,0-1.9,0.8-1.9,1.9v3H5.9C4.7,4.9,4,5.7,4,6.8s0.8,1.9,1.9,1.9h60.2C67.3,8.7,68,8,68,6.8S67.3,4.9,66.3,4.9z M24.7,58.9V17.1
                              c0-1.1-0.8-1.9-1.9-1.9s-1.9,0.8-1.9,1.9v41.8c0,1.1,0.8,1.9,1.9,1.9S24.7,60,24.7,58.9z M38,58.9V17.1c0-1.1-0.8-1.9-1.9-1.9
                              c-1.1,0-1.9,0.8-1.9,1.9v41.8c0,1.1,0.8,1.9,1.9,1.9C37.2,60.8,38,60,38,58.9z M51.3,58.9V17.1c0-1.1-0.8-1.9-1.9-1.9
                              s-1.9,0.8-1.9,1.9v41.8c0,1.1,0.8,1.9,1.9,1.9S51.3,60,51.3,58.9z"/>
                          </svg>
                        </BaseButton>
                      </div>
                      <div class="tw-flex-initial">
                        <button
                          class="group-hover:tw-opacity-100 opacity-18 tw-shadow-app transition-fast info-card__action tw-rounded-3 tw-fill-current tw-px-2 tw-py-1 tw-mr-2 tw-mb-2 tw-inline-block transition-fast tw-bg-app-deep-blue-11"
                          @click.prevent="editGoalType('pillarGroups', getGoalTypeComponentProps('pillarGroups', pillarGroup.id))"
                        >
                          <span class="tw-px-2 opacity-78 tw-inline-block tw-text-sm leading-17 tw-uppercase">Edit</span>
                        </button>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </ViewMore>

                <div></div>
                <div class="tw-border-t tw-border-app-black-11 tw-my-8"></div>

              </template>

              <!-- GOALS -->
              <template>

                <div class="tw-flex tw-items-center tw-justify-between tw-mt-8 tw-mb-3">
                  <h5 class="tw-text-sm tw-leading-tight tw-capitalize">Goals</h5>

                  <BaseButton
                    text="Add"
                    title="Create a Goal"
                    @click="editGoalType('goals', getGoalTypeComponentProps('goals'))"
                  />

                </div>

                <ViewMore
                  :list="companyGoals.goals.data"
                  :limit="4"
                  class="tw-flex tw-flex-wrap stacked-grid stacked-grid--stack-four lg:stacked-grid--stack-four"
                  v-slot="{ item: goal }"
                >
                  <div class="info-card tw-group tw-shadow-app tw-relative tw-flex tw-flex-col tw-justify-between">
                    <h4 class="tw-leading-tight tw-font-medium opacity-87 tw-text-base tw-px-2 tw-py-4 tw-break-words">{{ goal.goal }}</h4>
                    <!-- <h6 class="opacity-31 tw-text-xs leading-15 tw-overflow-hidden tw-m-2 tw-mt-0" style="max-height: 30px" >
                      {{ subGoal.description }}
                    </h6> -->
                    <div class="tw-flex tw-items-end tw-mt-3">
                      <div class="tw-flex-1">
                        <BaseButton
                          icon
                          text=""
                          confirm
                          @click="deleteGoalType('goals', goal.id)"
                          class="tw-border-none tw-bg-red-100 group-hover:opacity-78 hover:tw-opacity-100 opacity-11 tw-mb-2 tw-ml-2 tw-text-red-600 transition-slow"
                        >
                          <svg slot="icon" class="my-btn__icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                            y="0px" viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                            <path d="M63.1,15.2V64c0,4.4-3.6,8-8,8h-38c-4.4,0-8-3.6-8-8V15.2c0-1.1,0.8-1.9,1.9-1.9s1.9,0.8,1.9,1.9V64c0,2.3,1.9,4.2,4.2,4.2
                              h38c2.3,0,4.2-1.9,4.2-4.2V15.2c0-1.1,0.8-1.9,1.9-1.9S63.1,14.2,63.1,15.2z M66.3,4.9H38v-3C38,0.8,37.2,0,36.1,0
                              c-1.1,0-1.9,0.8-1.9,1.9v3H5.9C4.7,4.9,4,5.7,4,6.8s0.8,1.9,1.9,1.9h60.2C67.3,8.7,68,8,68,6.8S67.3,4.9,66.3,4.9z M24.7,58.9V17.1
                              c0-1.1-0.8-1.9-1.9-1.9s-1.9,0.8-1.9,1.9v41.8c0,1.1,0.8,1.9,1.9,1.9S24.7,60,24.7,58.9z M38,58.9V17.1c0-1.1-0.8-1.9-1.9-1.9
                              c-1.1,0-1.9,0.8-1.9,1.9v41.8c0,1.1,0.8,1.9,1.9,1.9C37.2,60.8,38,60,38,58.9z M51.3,58.9V17.1c0-1.1-0.8-1.9-1.9-1.9
                              s-1.9,0.8-1.9,1.9v41.8c0,1.1,0.8,1.9,1.9,1.9S51.3,60,51.3,58.9z"/>
                          </svg>
                        </BaseButton>
                      </div>
                      <div class="tw-flex-initial">
                        <button
                          class="group-hover:tw-opacity-100 opacity-18 tw-shadow-app transition-fast info-card__action tw-rounded-3 tw-fill-current tw-px-2 tw-py-1 tw-mr-2 tw-mb-2 tw-inline-block transition-fast tw-bg-app-deep-blue-11"
                          @click.prevent="editGoalType('goals', getGoalTypeComponentProps('goals', goal.groupid, goal.id))"
                        >
                          <span class="tw-px-2 opacity-78 tw-inline-block tw-text-sm leading-17 tw-uppercase">Edit</span>
                        </button>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </ViewMore>

                <div></div>
                <div class="tw-border-t tw-border-app-black-11 tw-my-8"></div>

              </template>

              <!-- SUB-GOALS -->
              <template v-if="false">

                <div class="tw-flex tw-items-center tw-justify-between tw-mt-8 tw-mb-3">
                  <h5 class="tw-text-sm tw-leading-tight tw-capitalize">Sub Goals</h5>

                  <BaseButton
                    text="Add"
                    title="Create a sub-goal"
                    @click="editGoalType('subGoals', getGoalTypeComponentProps('subGoals'))"
                  />

                </div>

                <ViewMore
                  :list="companyGoals.subGoals.data"
                  :limit="4"
                  class="tw-flex tw-flex-wrap stacked-grid stacked-grid--stack-four lg:stacked-grid--stack-four"
                  v-slot="{ item: subGoal }"
                >
                  <div class="info-card tw-group tw-shadow-app tw-relative tw-flex tw-flex-col tw-justify-between">
                    <h4 class="tw-leading-tight tw-font-medium opacity-87 tw-text-base tw-px-2 tw-py-4 tw-break-words">{{ subGoal.goal }}</h4>
                    <!-- <h6 class="opacity-31 tw-text-xs leading-15 tw-overflow-hidden tw-m-2 tw-mt-0" style="max-height: 30px" >
                      {{ subGoal.description }}
                    </h6> -->
                    <div class="tw-flex tw-items-end tw-mt-3">
                      <div class="tw-flex-1">
                        <BaseButton
                          icon
                          text=""
                          confirm
                          @click="deleteGoalType('subGoals', subGoal.id)"
                          class="tw-border-none tw-bg-red-100 group-hover:opacity-78 hover:tw-opacity-100 opacity-11 tw-mb-2 tw-ml-2 tw-text-red-600 transition-slow"
                        >
                          <svg slot="icon" class="my-btn__icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                            y="0px" viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                            <path d="M63.1,15.2V64c0,4.4-3.6,8-8,8h-38c-4.4,0-8-3.6-8-8V15.2c0-1.1,0.8-1.9,1.9-1.9s1.9,0.8,1.9,1.9V64c0,2.3,1.9,4.2,4.2,4.2
                              h38c2.3,0,4.2-1.9,4.2-4.2V15.2c0-1.1,0.8-1.9,1.9-1.9S63.1,14.2,63.1,15.2z M66.3,4.9H38v-3C38,0.8,37.2,0,36.1,0
                              c-1.1,0-1.9,0.8-1.9,1.9v3H5.9C4.7,4.9,4,5.7,4,6.8s0.8,1.9,1.9,1.9h60.2C67.3,8.7,68,8,68,6.8S67.3,4.9,66.3,4.9z M24.7,58.9V17.1
                              c0-1.1-0.8-1.9-1.9-1.9s-1.9,0.8-1.9,1.9v41.8c0,1.1,0.8,1.9,1.9,1.9S24.7,60,24.7,58.9z M38,58.9V17.1c0-1.1-0.8-1.9-1.9-1.9
                              c-1.1,0-1.9,0.8-1.9,1.9v41.8c0,1.1,0.8,1.9,1.9,1.9C37.2,60.8,38,60,38,58.9z M51.3,58.9V17.1c0-1.1-0.8-1.9-1.9-1.9
                              s-1.9,0.8-1.9,1.9v41.8c0,1.1,0.8,1.9,1.9,1.9S51.3,60,51.3,58.9z"/>
                          </svg>
                        </BaseButton>
                      </div>
                      <div class="tw-flex-initial">
                        <button
                          class="group-hover:tw-opacity-100 opacity-18 tw-shadow-app transition-fast info-card__action tw-rounded-3 tw-fill-current tw-px-2 tw-py-1 tw-mr-2 tw-mb-2 tw-inline-block transition-fast tw-bg-app-deep-blue-11"
                          @click.prevent="editGoalType('subGoals', getGoalTypeComponentProps('subGoals', subGoal.goalid, subGoal.id))"
                        >
                          <span class="tw-px-2 opacity-78 tw-inline-block tw-text-sm leading-17 tw-uppercase">Edit</span>
                        </button>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </ViewMore>

                <div class="tw-border-t tw-border-app-black-11 tw-my-8"></div>

              </template>
            </div>
          </div>

        </transition>

      </div>

    </transition>

  </AppView>
</template>

<script>
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';

import EditGoal from '@/views/Companies/Company/_partials/EditGoal.vue';
import EditSubGoal from '@/views/Companies/Company/_partials/EditSubGoal.vue';
import EditPillarGroup from '@/views/Companies/Company/_partials/EditPillarGroup.vue';
import ViewMore from '@/components/ViewMore.vue';
import UserPhoto from '@/components/UserPhoto.vue';

import companyMixin from '@/mixins/company';

export default {
  name: 'EditCompanyGoalsView',
  components: {
    EditPillarGroup,
    EditGoal,
    EditSubGoal,
    ViewMore,
    UserPhoto,
  },
  mixins: [companyMixin],
  props: ['id'],
  data() {
    return {
      companyGoals: {
        pillarGroups: {
          component: 'EditPillarGroup',
          data: [],
        },
        goals: {
          component: 'EditGoal',
          data: [],
        },
        subGoals: {
          component: 'EditSubGoal',
          data: [],
        },
      },
      // form component
      activeFormComponent: false,
      activeFormComponentOptions: {
        props: {},
      },
    };
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    async getCompanyGoals(company = this.company, calledByGetCompany = false) {
      if (!calledByGetCompany) {
        this.loadingCompany = true;
        this.errorCompany = undefined;
      }

      await Promise.all([
        this.$store.dispatch('getPillarGroups', [this.id, true]),
        this.$store.dispatch('getGoals', [this.id, true]),
        this.$store.dispatch('getSubGoals', [this.id, true]),
      ]).then(([pillarGroups, goals, subGoals]) => {
        this.companyGoals.pillarGroups.data = _reverse(_sortBy(pillarGroups || [], 'dateOfPost'));
        this.companyGoals.goals.data = _reverse(_sortBy(goals || [], 'dateOfPost'));
        this.companyGoals.subGoals.data = _reverse(_sortBy(subGoals || [], 'dateOfPost'));
      });

      await this.$nextTick();
      await this.$nextTick();

      if (!calledByGetCompany) {
        this.loadingCompany = false;
        this.errorCompany = undefined;
      }
    },

    // ---------------------------------------------------------

    async editGoalType(goalType, props = {}) {
      if (!goalType) return false;
      if (!this.companyGoals[goalType]) {
        console.error(`Goal Type [${goalType}] component or data not set`, this.companyGoals[goalType]);
        return false;
      }

      const goalTypeComponent = this.companyGoals[goalType].component;

      // load the right form component
      this.activeFormComponentOptions.props = { ...props };
      this.activeFormComponent = goalTypeComponent;

      await this.$nextTick();

      // open modal
      this.$store.commit('openModal', [true, 'modal']);
      return true;
    },

    // ---------------------------------------------------------

    async deleteGoalType(goalType, goalTypeId) {
      let actionName;

      switch (goalType) {
        case 'pillarGroups':
          actionName = 'deletePillarGroup';
          break;
        case 'goals':
          actionName = 'deleteGoal';
          break;
        case 'subGoals':
          actionName = 'deleteSubGoal';
          break;
        default:
          console.error('argument goalType typo on delete([goalType]). \nPassed :', goalType);
          break;
      }

      if (!actionName) return false;

      const deleted = await this.$store.dispatch(actionName, goalTypeId);

      await this.$nextTick();
      await this.$nextTick();

      this.loadingCompany = true;

      await this.$nextTick();
      await this.$nextTick();

      if (deleted) {
        this.$toasted.success('Deleted!', { duration: 1500 });
        await this.$nextTick();

        setTimeout(() => {
          this.getCompany();
          this.getCompanyGoals();
        }, 1200);
      } else {
        this.$toasted.global.appError();
      }

      return true;
    },

    // ---------------------------------------------------------

    // * generates props Object
    getGoalTypeComponentProps(goalType, ...args) {
      const props = {
        company: this.company,
      };

      switch (goalType) {
        case 'pillarGroups':
          [props.groupId] = args;
          break;

        case 'goals':
          [props.groupId, props.goalId] = args;
          break;

        case 'subGoals':
          [props.goalId, props.subGoalId] = args;
          break;

        default:
          break;
      }

      // console.log(props);
      return props;
    },

    // ---------------------------------------------------------

    canDeleteGoalType(parentGoalTypeId, childGoalType, childGoalTypeParentProp) {
      return (!!this.companyGoals[childGoalType].data.find((x) => x[childGoalTypeParentProp] === parentGoalTypeId) === false);
    },
  },
  async created() {
    // get company
    await Promise.all([
      this.getCompany(),
    ]);

    await this.$nextTick();

    // assert user company permissions
    if (!this.hasPermission) {
      this.$router.push({
        name: 'app',
      });

      return false;
    }

    await this.getCompanyGoals();

    await this.$nextTick();
    await this.$nextTick();

    this.loadingCompany = false;
    return true;
  },
};
</script>

<style>

</style>
